import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import { Container, Row, Col } from "react-grid-system"
import AspectRatio from "react-aspect-ratio"
import "react-aspect-ratio/aspect-ratio.css"
import logo from "../../logo.gif"

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___index], order: ASC }
          filter: { frontmatter: { hidden: { eq: false } } }
        ) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                menu
                date(formatString: "DD MMMM, YYYY")
                hidden
              }
              fields {
                slug
              }
            }
          }
        }
        markdownRemark(frontmatter: { footer: { eq: true } }) {
          id
          html
        }
      }
    `
  )
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 865px;
        background-color: #edebe4;
        height: 100%;
        min-height: 100vh;
        box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
      `}
    >
      <main
        css={css`
          margin: 0 auto;
          max-width: 800px;
          padding: 15%;
          padding-top: ${rhythm(1.5)};
          background-color: white;
          height: 100%;
          min-height: 100vh;
          box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
          font-family: Didot, serif;
        `}
      >
        <Container>
          <Row>
            <Col
              css={css`
                padding: 1rem;
              `}
            >
              <Link to="/">
                <AspectRatio
                  ratio="366/254"
                  style={{ maxWidth: "300px", margin: "auto" }}
                >
                  <img src={logo} alt="" />
                </AspectRatio>
              </Link>
            </Col>
          </Row>
          <Row
            css={css`
              border-style: ridge hidden ridge hidden;
            `}
          >
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <Col>
                <nav key={node.id}>
                  <Link
                    to={node.fields.slug}
                    css={css`
                      text-decoration: none;
                      color: inherit;
                      vertical-align: middle;
                    `}
                  >
                    <h2
                      css={css`
                        margin-top: ${rhythm(1 / 4)};
                        margin-bottom: ${rhythm(1 / 4)};
                        text-align: center;
                        white-space: nowrap;
                        font-family: Didot, serif;
                        font-size: 14px;
                      `}
                    >
                      {node.frontmatter.menu}
                    </h2>
                  </Link>
                </nav>
              </Col>
            ))}
          </Row>
          <Row>
            <Col
              css={css`
                padding: ${rhythm(2)};
                padding-top: ${rhythm(0.2)};
                border-style: hidden hidden ridge hidden;
                font-size: 16px;
              `}
            >
              {children}
            </Col>
          </Row>
          <Row>
            <Col
              css={css`
                padding-top: ${rhythm(0.5)};
                font-family: Didot, serif;
                font-size: 14px;
              `}
            >
              <div
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  )
}
